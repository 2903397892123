<template>
  <v-main>
    <v-container fluid>
      <router-view :key="$route.path" />
      <v-snackbar v-model="notification.show" :top="true">
        <v-card>
          <v-card-title>
            <span class="headline"> {{ notification.title }}</span>
          </v-card-title>
          <v-card-text>
            {{ notification.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark text @click="notification.show = false">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-snackbar>
    </v-container>
  </v-main>
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "DefaultView",
  computed: {
    ...sync("auth", ["notification"]),
  },
};
</script>
